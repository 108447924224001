<template>
 <b-card title="Average Score by Category" v-if="value">
    <app-echart-bar
      :option-data="options"
    />
  </b-card>
    
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    // VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    AppEchartBar
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options:{
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {
          top: 'bottom'
        },
        grid: {
            left: '12%',
            right: '30px',
            bottom: '30px',
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: this.value && this.value.list,
            splitLine: { show: false },
        },
        series: this.value && this.value.series
      }
    }
  },
  mounted(){
    // console.log("prop", this.value);
  },
  watch:{
    value(newVal){
      if(!newVal) return;
      this.options = {
        tooltip: {
              trigger: 'axis',
              axisPointer: {
                  // Use axis to trigger tooltip
                  type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
              }
          },
          legend: {},
          grid: {
              left: '100px',
              right: '30px',
              bottom: '30px',
          },
          xAxis: {
              type: 'value'
          },
          yAxis: {
              type: 'category',
              data: newVal && newVal.list,
              splitLine: { show: false },
          },
          series: newVal && newVal.series
      }
    }
  }
}
</script>